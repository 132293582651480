.react-date-picker {
    box-shadow: 0 1px 1px 0 #0000001A;
    &__triangle {
      border-bottom-color: map-get($map: $colors, $key: "beplBlue")!important;
      display: none;
    }
    &__header {
      border: none!important;
    }
    &__header, &__day--selected {
      background-color: map-get($map: $colors, $key: "beplBlue")!important;
    }
    &__current-month, &-time__header, &__day-name, &__week, &__day--selected {
      color: map-get($map: $colors, $key: "white")!important;
    }
    &__navigation--previous {
      border-right-color: map-get($map: $colors, $key: "white")!important;
    }
    &__navigation--next {
      background-color: map-get($map: $colors, $key: "beplBlue")!important;
      border-left-color: map-get($map: $colors, $key: "white")!important;
    }
    &__wrapper {
      border: none;
    }
    &__clear-button {
      display: none;
    }
    &__calendar {
      z-index: 4;
    }
    &__calendar-button {
      display: none;
      svg{
        stroke: map-get($map: $colors, $key: "grayDark");
      }
    }
    &__inputGroup {
      margin-top: 1px;
      text-align: center;
      &__month {
        appearance: none;
        text-align: center;
        border-right: solid 1px map-get($map: $colors, $key: "grayLight");
        margin-right: 2px;
        padding-right: 5px;
      }
    }
}

.react-calendar {
  border-color: map-get($map: $colors, $key: "beplBlue");
  border-radius: 5px;
  z-index: 4;
  &__navigation {
    background: map-get($map: $colors, $key: "beplBlue")!important;
    margin-bottom: 0px;
    border-bottom: solid 1px map-get($map: $colors, $key: "beplBlue");
    button {
      color: white;
      &[disabled] {
        color: map-get($map: $colors, $key: "grayDark");
      }
    }
    &__prev2-button {
      border-top-left-radius: 5px;
    }
    &__next2-button {
      border-top-right-radius: 5px;
    }
  }
  &__tile {
    &--now {
      background: map-get($map: $colors, $key: "beplMagenta");
      color: white;
    }
    &--active {
      background-color: map-get($map: $colors, $key: "beplBlue");
    }
  }
}

.react-date-picker__wrapper input:disabled {
  background-color: map-get($map: $colors, $key: "muted");
}

.react-date-picker__popper {
  z-index: 4;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 2.1rem;
  margin: 0.18rem
}

.react-datepicker__week {
  padding: 0.18rem 0;
}

.MuiOutlinedInput-root {
  background-color: white;
}

input.MuiOutlinedInput-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

button.PrivatePickersMonth-root:disabled{
  display: none;
}