
.input-custom {
  border-radius: 2px;
  line-height: 28px;
  font-size: 14px;
  text-align: left;
  background: map-get($map: $colors, $key: "white");
  border: 1px solid map-get($map: $colors, $key: "grayLight");
  padding-left: 5px;
  color:map-get($map: $colors, $key: "black");
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: map-get($map: $colors, $key: "grayDark")
  }
  &.is-invalid {
    background-image: none;
  }
  &.search {
    padding-left: 40px;
    &+.icon-search {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
}

.form-control.is-invalid {
  background-image: none;
}

.additionalFiltersContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.additionalFiltersContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid map-get($map: $colors, $key: "grayLight");
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.additionalFiltersContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.additionalFiltersContainer input:checked ~ .checkmark {
  background-color: map-get($map: $colors, $key: "beplBlue");
  border-color: map-get($map: $colors, $key: "beplBlue");
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.additionalFiltersContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.additionalFiltersContainer .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid map-get($map: $colors, $key: 'grayDark');
  outline: 0;
  font-size: 18px;
  padding: 7px 0px;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .login-label {
    font-size: 18px;
    cursor: text;
    top: -35px;
  }
}

.as-form-field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid map-get($map: $colors, $key: 'grayDark');
  outline: 0;
  font-size: 18px;
  padding: 7px 0px;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .login-label {
    font-size: 18px;
    cursor: text;
    top: -35px;
  }
}

.login-label {
  position: relative;
  top: -60px;
  transition: 0.2s;
  font-size: 16px;
  color: map-get($map: $colors, $key: 'grayDark');
  pointer-events: none;
}

.form-field:focus {
  ~ .login-label {
    position: relative;
    top: -60px;
    transition: 0.2s;
    font-size: 16px;
    color: map-get($map: $colors, $key: 'beplBlue');
    font-weight:700;  
    pointer-events: none;  
  }
  background: transparent;
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, map-get($map: $colors, $key: 'beplBlue'), map-get($map: $colors, $key: 'beplMagenta'));
  border-image-slice: 1;
}
/* reset input */
.form-field{
  &:required,&:invalid { box-shadow:none; }
}

.as-form-field:focus {
  ~ .login-label {
    position: relative;
    top: -60px;
    transition: 0.2s;
    font-size: 16px;
    color: map-get($map: $colors, $key: 'beplBlue');
    font-weight:700;  
    pointer-events: none;  
  }
  background: transparent;
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, map-get($map: $colors, $key: 'asBlue'), map-get($map: $colors, $key: 'brightBlue'));
  border-image-slice: 1;
}
/* reset input */
.as-form-field{
  &:required,&:invalid { box-shadow:none; }
}

.input-group-text {
  background-color: white;
  color: gray;
  border-left: 1px solid #ced4da;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-right: 0px solid #ced4da;
  padding-right: 4px;
}

.text-input {
  border-left: 0px solid #ced4da;
  padding-left: 0px !important;

  &:focus {
    border-color: #ced4da;

    &::placeholder {
      color: transparent;
    }
  }
}

input:focus {
  border-color: #ced4da;

  &::placeholder {
    color: transparent;
  }
}

#dataSuiteDateSearch, #dataSuiteDetailsDateSearch, #dataSuiteDetailsPageDateSearch, #exportDateSearch, #compExportDateSearch {
  height: 38px;
}

div#datasuiteDetailsPageSearchOptions {
  width: 120px;
}

#includedTags {
  margin-right: 12px;
}

.react-tags-wrapper {
  display: inline-flex;
}