.table {
    thead {
        tr {
            font-family: 'Roboto', sans-serif;
            font-weight: 600;
            font-size: 12px;
            color: #76777B !important;
            background: white;
            position: sticky;
            top: 0;  
            background-color: #F8F8FA;

            th {
                color: #76777B;
                background: transparent;
            }

            svg {
                font-size: small;
            }
        }
    }

    tr > *:nth-child(1) {
        padding-left: 1rem;
    }

    .ReactVirtualized__Table__headerRow {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: #76777B !important;
        background: white;
        position: sticky;
        top: 0;  
        background-color: #F8F8FA;
        
        .ReactVirtualized__Table__headerTruncatedText {
            padding-bottom: 2px;
            padding-left: 2px;
            color: #76777B;
            background: transparent;
        }

        svg {
            font-size: small;
        }
    }

    .ReactVirtualized__Table__Grid {
        border-top: 1px solid lightgrey !important;

        .ReactVirtualized__Table__row {
            border-bottom: 1px solid lightgrey !important;
        }
    }
}

.ReactVirtualized__Table__row:hover {
    background: map-get($map: $colors, $key: 'grayNew');
}

table > :not(:first-child)  {
        border-top: 1px solid map-get($map: $colors, $key: 'grayLight') !important;
}



.categoriesOverviewTable tr > *:nth-child(4)  {
    text-align: end;
    padding-right: 1rem;
}

#competitivePurchasesTable th > svg {
    margin-bottom: 2px;
}

#competitivePurchasesTable thead > tr {
    background-color: map-get($map: $colors, $key: 'grayLighter');
    th {
        color: black !important;
        font-weight: 600;
        svg {
            font-size: small;
        }
    }
}

#manufacturerPurchasesTable th > svg {
    margin-bottom: 2px;
}

#manufacturerPurchasesTable thead > tr {
    background-color: map-get($map: $colors, $key: 'grayLighter');
    th {
        color: black !important;
        font-weight: 600;
        svg {
            font-size: small;
        }
    }
}

#usersTable th > svg {
    margin-bottom: 2px;
}

#usersTable thead > tr {
    background-color: map-get($map: $colors, $key: 'grayLighter');
    th {
        color: black !important;
        font-weight: 600;
        svg {
            font-size: small;
        }
    }
}

#marketingCampaignsTable th > svg,
#topCategoriesTable th > svg {
    margin-bottom: 2px;
}

.searchFilterApplied {
    .ReactVirtualized__Table__rowColumn.searchHighlight {
        color: map-get($map: $colors, $key: 'blueFilter');
        font-weight: 600;
    }
}
