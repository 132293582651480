.side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 90%;
    }

    #searchInput {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
    }

    .valueRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .valueRange {
            border: none;
            width: 84%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .valueRange:focus {
            outline: none;
        }
    }

    .locationRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .locationRange {
            border: none;
            width: 98%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .locationRange:focus {
            outline: none;
        }
    }
 }

 .side-drawer.open {
    transform: translateX(0);
 }

 .ds-side-drawer { 
    height: 100vh;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100vw - 249px);
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 90%;
    }

    #searchInput {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
    }

    .valueRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .valueRange {
            border: none;
            width: 84%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .valueRange:focus {
            outline: none;
        }
    }

    .locationRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .locationRange {
            border: none;
            width: 98%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .locationRange:focus {
            outline: none;
        }
    }
 }

 .ds-side-drawer.open {
    transform: translateX(0);
 }

 .side-drawer-mobile { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }

    .valueRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .valueRange {
            border: none;
            width: 70%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .valueRange:focus {
            outline: none;
        }
    }

    .locationRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .locationRange {
            border: none;
            width: 98%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .locationRange:focus {
            outline: none;
        }
    }
 }

 .side-drawer-mobile.open {
    transform: translateX(0);
 }

 .ds-side-drawer-mobile { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
    transform: translateX(100%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }

    .valueRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .valueRange {
            border: none;
            width: 70%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .valueRange:focus {
            outline: none;
        }
    }

    .locationRangeInputs {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
        height: 100%;

        span {
            float: left;
            margin: 5px;
        }

        .locationRange {
            border: none;
            width: 98%;
            float: right;
            margin: 2px;
            padding-top: 3px;
        }

        .locationRange:focus {
            outline: none;
        }
    }
 }

 .ds-side-drawer-mobile.open {
    transform: translateX(0);
 }

 .left-side-drawer { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 250px;
    width: 50%;
    z-index: 4;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1); 
    transform: translateX(-125%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 90%;
    }
 }

 .left-side-drawer.open {
    transform: translateX(0);
 }

 .left-side-drawer-mobile { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 250px;
    width: 200%;
    z-index: -1;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(-180%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }
 }

 .left-side-drawer-mobile.open {
    transform: translateX(0);
 }

 .left-side-drawer-menu-mobile { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0px;
    width: 250px;
    z-index: 100;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(-125%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }
 }

 .left-side-drawer-menu-mobile.open {
    transform: translateX(0);
 }

 .left-side-drawer-menu-mobileonly { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100vw;
    z-index: 1;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(-155%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }
 }

 .left-side-drawer-menu-mobileonly.open {
    transform: translateX(0);
 }

 .left-side-drawer-mobileonly { 
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100vw;
    z-index: 2;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(-150%);
    transition: transform 0.3s ease-out;

    .drawerButtons {
        position: absolute;
        bottom: 4px;
        width: 80%;
        margin-bottom: 4vh !important;
    }
 }

 .left-side-drawer-mobileonly.open {
    transform: translateX(0);
 }

 #filtersDrawer, #filtersDetailsDrawer, #filtersDetailsPageDrawer {
    transition: top 0.3s ease-out;
 }

 #filtersDetailsPageDrawer {
    width: 83.2% !important;
 }

 #dataSuiteFiltersCard {
    transition: height 0.3s ease-out;
 }

 #filtersDetailsMain, #filtersDetailsPageMain {
    transition: margin-bottom 0.3s ease-out;
 }

 #filtersDetailsPageMain {
    border: 1px solid lightgray;
    border-radius: 5px;
 }

 .dataSuiteSlideDrawer {
    width: fit-content !important;
 }