.marketingCampaignDetailsCard {
    border: solid 4px map-get($map: $colors, $key: 'grayLight');
}

.marketingCampaignDetailsCard:read-only {
    border-style: hidden !important;
}

.marketingCampaignLearningsCard {
    border-color: map-get($map: $colors, $key: 'grayLight');
}

.campaign-management-nav-label,
.dashboard-nav-label {
    font-size: 18px;
    font-weight: 400;
}