.summaryContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.compInsightsContainer {
    min-height: 92.5vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.compInsightsContainerMobile {
    min-height: 92.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}

.compInsightsDetailsContainer {
    min-height: 92.5vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.compInsightsDetailsContainerMobile {
    min-height: 92.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}

.opportunitiesContainer {
    left: 75px;
    min-height: 92.5vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.opportunitiesContainerMobile {
    min-height: 92.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
}

.marketingCampaignsContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: 16px;
    margin-right: 16px;

    #searchInput {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
    }
}

.marketingCampaignsContainerMobile {
    min-height: 92.5vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw; 
    
    #searchInput {
        border: 1px solid map-get($map: $colors, $key: "grayLight");
        border-radius: 2px;
    }
}

.marketingCampaignsDetailsContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.settingsContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}