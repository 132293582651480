.ciDetailsSearchInput {
    width: 150px;
}

.ciDetailsDropdown {
    width: 150px;
}

.dividerRight {
    border-right: 1px solid map-get($map: $colors, $key: 'grayLight');
}

.compInsightsDetailsContainer {
    .dashboard-card {
        height: 84.5vh;
    }
}

.compInsightsDetailsContainerMobile {
    .dashboard-card {
        height: 84.5vh;
    }
}

#categoriesFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}

#segmentFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}

#customersFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}

#locationsFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}