/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($map: $colors, $key: "grayDarker");
}
::-webkit-scrollbar-track {
  background: map-get($map: $colors, $key: "grayLighter");
}
::-webkit-scrollbar-track:hover {
  background: map-get($map: $colors, $key: "grayLight");
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($map: $colors, $key: "gray");
  border-radius: 5px;
}