
.label-text-bar {
    color: map-get($map: $colors, $key: "white");
    font-weight: 400;
}

.label-text-header {
    color: map-get($map: $colors, $key: "muted");
    font-weight: 400;
    letter-spacing: 0.5px;
}

.label-text {
    color: map-get($map: $colors, $key: "black");
    font-weight: 400;
}