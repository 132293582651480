.MuiSlider-rail {
    height: 10px !important;
    color: map-get($map: $colors, $key: 'grayDarker');
}

.MuiSlider-track {
    height: 10px !important;
    color: map-get($map: $colors, $key: 'beplBlue');
}

.MuiSlider-thumbColorPrimary {
    color: map-get($map: $colors, $key: 'white');
}

#valueRangeSlider.Mui-disabled {
    .MuiSlider-rail {
        height: 10px !important;
        color: map-get($map: $colors, $key: 'grayDarker');
    }
    
    .MuiSlider-track {
        height: 10px !important;
        color: map-get($map: $colors, $key: 'grayDarker');
    }

    .MuiSlider-thumbColorPrimary {
        color: map-get($map: $colors, $key: 'grayDark');
    }
}

#locationRangeSlider.Mui-disabled {
    .MuiSlider-rail {
        height: 10px !important;
        color: map-get($map: $colors, $key: 'grayDarker');
    }
    
    .MuiSlider-track {
        height: 10px !important;
        color: map-get($map: $colors, $key: 'grayDarker');
    }

    .MuiSlider-thumbColorPrimary {
        color: map-get($map: $colors, $key: 'grayDark');
    }
}