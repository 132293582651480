.navbar {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
    height: 100vh;
    width: 250px;
    padding: var(--mantine-spacing-md);
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-right: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .navbarmobile {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
    height: 100vh;
    width: 100vw;
    padding: var(--mantine-spacing-md);
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  .header {
    padding: var(--mantine-spacing-md);
    padding-top: 0;
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .links {
    flex: 1;
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
  }
  
  .linksInner {
    padding-top: var(--mantine-spacing-md);
    padding-bottom: var(--mantine-spacing-md);
  }

  .linksBottom {
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    padding-top: var(--mantine-spacing-md);
    padding-bottom: var(--mantine-spacing-md);
    border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .footer {
    margin-left: calc(var(--mantine-spacing-md) * -1);
    margin-right: calc(var(--mantine-spacing-md) * -1);
    border-top: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }