

//Max width fc
.w-fc {
  max-width: fit-content;
}

//Max width
.mw-25px {
  max-width: 25px;
}
.mw-70px {
  max-width: 70px;
}
.mw-125px {
  max-width: 125px;
}
.mw-100px {
  max-width: 100px;
}
.mw-340px {
  max-width: 340px;
}
.mw-400px {
  max-width: 400px;
}
.mw-960px {
  max-width: 960px;
}
.mw-990px {
  max-width: 990px;
}
.mmw-90px {
  min-width: 90px;
  max-width: 90px;
}
.mmw-100px {
  min-width: 100px;
  max-width: 100px;
}
.mmw-125px {
  min-width: 125px;
  max-width: 125px;
}
.mmw-145px {
  min-width: 145px;
  max-width: 145px;
}
.mmw-175px {
  min-width: 175px;
  max-width: 175px;
}
.mmw-165px {
  min-width: 165px;
  max-width: 165px;
}
//Height
.h-40px {
  height: 40px;
}

.h-92px {
  height: 92px;
}

.h-16px {
  height: 16px;
}

.px-15px  {
  padding-left: 15px;
  padding-right: 15px;
}