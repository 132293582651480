$theme-colors : (
  //Blue
  'primary': #314175,
  'blue': #0071CE,
  'blueDark': #23408F,
  'blueBasic': #006DBA,
  'blueSec': #334F9A,
  'blueFilter': #49A4DA,
  //White
  'white': #FFFFFF,
  //Black
  'black': #202020,
  'blackShadow': #00001A,
  //Gray
  'gray': #8D8D90,
  'grayLight': #D7D8D9,
  'grayLighter': #EAEAEA,
  'grayDark': #909090,
  'grayDarker': #7F8083,
  'grayNew': #F5F5F5,
  'grayText' : #76777B,
  'muted': #76777B,
  'grayBg' : #F0F0F2,
  //Yellow
  'yellow': #FA9016,
  'yellowDark': #F18C18,
  //Purple
  'purple': #766ED7,
  //Green
  'green': #00C283,
  'mutedGreen': #009D9A,
  //Red
  'danger': #DC3545,
  'red': #DC3545,
  'redLight':#E36060,
  'mutedRed': #CF3F3F,
  //Pink
  'magentaLight':#FAEBF2,
  // Buyers Edge Platform colors
  'beplBlue':#314175,
  'beplDarkBlue':#1D1B4C,
  'beplMagenta':#CF3F7C,
  // ArrowStream colors
  //Blue
  'asBlue': #0F1640,
  'brightBlue': #08A6CC,
  'lightGrayBlue1': #EFF5F5,
  'lightGrayBlue2': #E2EBEE,
  'darkBrightBlue': #2194AF,
  //Gray
  'asGray': #7A7A7A,
  'asDarkGray': #2E2E2E,
  //Green
  'vibrantGreen': #CBDB2A,
  //Orange
  'orange': #F58220,
  //Red
  'brightRed': #F86464
);

$colors : (
  //Blue
  'primary': #314175,
  'blue': #0071CE,
  'blueDark': #23408F,
  'blueBasic': #006DBA,
  'blueSec': #334F9A,
  'blueFilter': #49A4DA,
  //White
  'white': #FFFFFF,
  //Black
  'black': #202020,
  'blackShadow': #00001A,
  //Gray
  'gray': #8D8D90,
  'grayLight': #D7D8D9,
  'grayLighter': #EAEAEA,
  'grayDark': #909090,
  'grayDarker': #7F8083,
  'grayNew': #F5F5F5,
  'grayText' : #76777B,
  'grayBg' : #F0F0F2,
  //Yellow
  'yellow': #FA9016,
  'yellowDark': #F18C18,
  //Purple
  'purple': #766ED7,
  //Green
  'green': #00C283,
  'mutedGreen': #009D9A,
  //Red
  'danger': #DC3545,
  'red': #DC3545,
  'redLight':#E36060,
  'mutedRed': #CF3F3F,
  //Pink
  'magentaLight':#FAEBF2,
  // Buyers Edge Platform colors
  'beplBlue':#314175,
  'beplDarkBlue':#1D1B4C,
  'beplMagenta':#CF3F7C,
  // ArrowStream colors
  //Blue
  'asBlue': #0F1640,
  'brightBlue': #08A6CC,
  'lightGrayBlue1': #EFF5F5,
  'lightGrayBlue2': #E2EBEE,
  'darkBrightBlue': #2194AF,
  //Gray
  'asGray': #7A7A7A,
  'asDarkGray': #2E2E2E,
  //Green
  'vibrantGreen': #CBDB2A,
  //Orange
  'orange': #F58220,
  //Red
  'brightRed': #F86464
);

//Modify boostrap defaults
$border-radius: 2px;
$input-focus-box-shadow: none!important;
$btn-focus-box-shadow: none!important;
$modal-header-padding: 1.5rem;
$modal-inner-padding: 1.5rem;

//Import
@import '@mantine/core/styles.css';
@import 'bootstrap/scss/bootstrap.scss';
@import './Input.scss';
@import './Label.scss';
@import './Header.scss';
@import './DatePicker.scss';
@import './Sizes.scss';
@import './Card.scss';
@import './Spinner.scss';
@import './Modal.scss';
@import './Scrollbar.scss';
@import './PageContainer.scss';
@import './Tables.scss';
@import './Slider.scss';
@import './CompetitiveInsightsDetails.scss';
@import './SlideDrawer.scss';
@import "@fontsource/poppins/600";
@import "@fontsource/poppins/700";
@import "react-toastify/scss/main.scss";
@import './Toast.scss';
@import './Tooltip.scss';
@import './Opportunities.scss';
@import './MarketingCampaigns.scss';
@import 'bootstrap-daterangepicker/daterangepicker.css';
@import 'react-virtualized/styles.css';
@import './Chatbot.scss';
@import './Carousel.scss';
@import './AppMenu.scss';

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.clickable {
  cursor: pointer;
}

.button-label,
.form-label,
.top-nav-label,
.see-details-label,
.show-more-less-label, 
.reveal-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; // SemiBold
}

.main-header,
.intel-graph-header {
  font-family: 'Roboto', sans-serif;
  font-weight: 600; // Bold
  color: map-get($map: $colors, $key: "black");
}

//ToolTip
.tooltip.show {
  opacity: 1;
}

//Border
.border {
  border-color: map-get($map: $colors, $key: "grayLight")!important;
}
.border-top {
  border-top: solid 1px map-get($map: $colors, $key: "grayLight");
}
.border-bottom {
  border-bottom: solid 1px map-get($map: $colors, $key: "grayLight");
}


//Text
.text-size {
  &-10 {
    font-size: 10px;
  }
  &-11 {
    font-size: 11px;
  }
  &-12 {
    font-size: 12px;
  }
  &-13 {
    font-size: 13px;
  }
  &-14 {
    font-size: 14px;
  }
  &-15 {
    font-size: 15px;
  }
  &-16 {
    font-size: 16px;
  }
  &-17 {
    font-size: 17px;
  }
  &-18 {
    font-size: 18px;
  }
  &-19 {
    font-size: 19px;
  }
  &-20 {
    font-size: 20px;
  }
  &-22 {
    font-size: 22px;
  }
  &-24 {
    font-size: 24px;
  }
  &-26 {
    font-size: 26px;
  }
  &-28 {
    font-size: 28px;
  }
  &-30 {
    font-size: 30px;
  }
  &-32 {
    font-size: 32px;
  }
  &-34 {
    font-size: 34px;
  }
  &-36 {
    font-size: 36px;
  }
  &-42 {
    font-size: 42px;
  }
}

//Line Height
.line-height {
  &-10 {
    line-height: 10px;
  }
  &-11 {
    line-height: 11px;
  }
  &-12 {
    line-height: 12px;
  }
  &-13 {
    line-height: 13px;
  }
  &-14 {
    line-height: 14px;
  }
  &-15 {
    line-height: 15px;
  }
  &-16 {
    line-height: 16px;
  }
  &-17 {
    line-height: 17px;
  }
  &-18 {
    line-height: 18px;
  }
  &-19 {
    line-height: 19px;
  }
  &-20 {
    line-height: 20px;
  }
  &-21 {
    line-height: 21px;
  }
  &-24 {
    line-height: 24px;
  }
  &-38 {
    line-height: 38px;
  }
}

.company-logo {
  max-height: 65px;
  max-width: 190px;
}

.company-icon {
  max-height: 48px;
  max-width: 160px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.react-virtualized-menu-placeholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
}

.react-virtualized-list-wrapper {
  div {
    overflow-x: hidden;
  }

  li {
    list-style: none;
    white-space: nowrap;
  }
}

#main-content {
  transition: all 0.5s ease;
  height: 100vh;
  position: absolute;
}

#locationsLinkSelected,
#topCatsProdsLinkSelected,
#cmLinkSelected,
#profileLinkSelected{
    box-shadow: 0px 4px 0 0px map-get($map: $colors, $key: 'beplMagenta');
}

#asLocationsLinkSelected,
#asTopCatsProdsLinkSelected,
#asCmLinkSelected,
#asProfileLinkSelected{
    box-shadow: 0px 4px 0 0px map-get($map: $colors, $key: 'brightBlue');
}

#locationsLinkSelected span,
#topCatsProdsLinkSelected span,
#cmLinkSelected span,
#profileLinkSelected span,
#linkSelected span{
    font-weight: 600;
}

#asLocationsLinkSelected span,
#asTopCatsProdsLinkSelected span,
#asCmLinkSelected span,
#asProfileLinkSelected span,
#asLinkSelected span{
    font-weight: 600;
}

@keyframes horizontalShift {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: horizontalShift;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}