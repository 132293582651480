#noti-count {
    position:absolute;
    top:-5px;
    right:-5px;
    background-color:map-get($map: $colors, $key: 'red');
    color:#fff;
    padding:5px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    width:10px;
    height:10px;
    text-align:center;
}
#noti-count div {
    margin-top:7px;
}

.Muitooltip-tooltip {
    background-color: aqua;
    color: bisque;
}