#header {
  &-relative {
    position: relative;
  }
  &-container {
    top: 0;
    left: 0;
    right: 0;
    float: left;
    position: fixed;
  }
}

#subheader { 
  &-container {
    background-color: transparent;
  }
}

#footer {
  &-relative {
    position: relative;
  }
  &-container {
    left: 0;
    right: 0;
    bottom: 0;
    float: left;
    position: fixed;
  }
}

.header-headroom {
  .headroom.headroom--unpinned {
    top: 96px!important;
    border-bottom: 1px solid #dee2e6;
    left: 31px!important;
    right: 31px!important;
  }
}

.beplGradient {
  background: rgb(29,27,76);
  background: -moz-linear-gradient(45deg, rgba(29,27,76,1) 0%, rgba(49,65,117,1) 30%, rgba(207,63,124,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(29,27,76,1) 0%, rgba(49,65,117,1) 30%, rgba(207,63,124,1) 100%);
  background: linear-gradient(45deg, rgba(29,27,76,1) 0%, rgba(49,65,117,1) 30%, rgba(207,63,124,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d1b4c",endColorstr="#cf3f7c",GradientType=1);
}

.arrowstreamGradient {
  background: rgb(15, 22, 64);
  background: -moz-linear-gradient(45deg, rgb(15, 22, 64) 0%, rgba(49,65,117,1) 30%, rgb(8, 166, 204) 100%);
  background: -webkit-linear-gradient(45deg, rgb(15, 22, 64) 0%, rgba(49,65,117,1) 30%, rgb(8, 166, 204) 100%);
  background: linear-gradient(45deg, rgb(15, 22, 64) 0%, rgba(49,65,117,1) 30%, rgb(8, 166, 204) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0F1640",endColorstr="#08A6CC",GradientType=1);
}