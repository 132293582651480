.card {
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashboard-card {
  height: 350px;
}

.compInsightsContainer .dashboard-card {
  height: 380px;
}

.opportunitiesContainer .dashboard-card {
  height: 87vh;
}

.opportunitiesContainerMobile .dashboard-card {
  height: 79vh;
  top: 15px;
}

.marketingCampaignsDetailsContainer .dashboard-card {
  height: 43vh;
}

#dataSuiteFiltersCard {
  #moreFiltersButton {
    color: white;
  }
}

#clearFiltersButton:hover {
  color: white;
}

.changeApplicationCard{ 
  transition: background 0.5s ease-in-out;
}

.changeApplicationCard:hover {
  background: rgb(235, 234, 234);
  cursor: pointer;
}
