.mantine-Carousel-indicator {
    background-color: rgb(129, 129, 129);
}

.mantine-Carousel-control{
    background-color: #CF3F7C;
    color: white;
    margin-right: -15px;
    margin-left: -15px;
    &[data-inactive] {
        opacity: 0;
        cursor: default;
    }
      
    
}
