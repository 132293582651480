#oppSourceFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}

#oppStatusFiltersList {
    max-height: 33vh;
    border: 1px solid map-get($map: $colors, $key: 'grayLight');
    border-radius: 2px;
}